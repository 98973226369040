(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/funds/assets/javascripts/funds-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/funds/assets/javascripts/funds-actions.js');
"use strict";


const {
  createAsyncThunk
} = RTK;

const fetchJackpots = createAsyncThunk('fetchJackpots', async _ref => {
  let {
    callback,
    products
  } = _ref;
  try {
    const jackpots = await svs.content.shared.getJackpots(products);
    callback(null, jackpots);
    return jackpots;
  } catch (error) {
    callback(error);
    throw error;
  }
});

const fetchFunds = createAsyncThunk('fetchFunds', _ref2 => {
  let {
    callback,
    products
  } = _ref2;
  return new Promise((resolve, reject) => {
    try {
      const funds = svs.components.lbUtils.funds.getFunds({
        products
      });
      callback(null, funds);
      resolve(funds);
    } catch (error) {
      callback(error);
      reject(error);
    }
  });
});
setGlobal('svs.components.marketplaceData.funds.actions', {
  fetchJackpots,
  fetchFunds
});

 })(window);